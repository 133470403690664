export default [
    {
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon',
        action: 'read',
        resource: 'ACL'
    },
    {
        title: 'Clientes',
        icon: 'BriefcaseIcon',
        children: [
            {
                title: 'Lista',
                route: 'customers',
            }
        ]
    },
    {
        title: 'Responsables',
        icon: 'UsersIcon',
        children: [
            {
                title: 'Lista',
                route: 'responsibles',
            }
        ]
    },
    {
        title: 'Expositores',
        icon: 'VolumeIcon',
        children: [
            {
                title: 'Lista',
                route: 'speakers',
            }
        ]
    },
    {
        title: 'Usuarios',
        icon: 'SmileIcon',
        children: [
            {
                title: 'Lista',
                route: 'users',
                action: 'read',
                resource: 'ACL'
            }
        ]
    },
    {

        title: 'Cursos',
        icon: 'BookIcon',
        children: [
            {
                title: 'Lista',
                route: 'courses',                
                action: 'read',
                resource: 'ACL'
            },
            {
                title: 'Agregar',
                route: 'courses-create',
            },
            {
                title: 'Notas',
                route: 'courses-qualifications',
                action: 'read',
                resource: 'ACL'
            }
        ]
    },
    {
        title: 'Preguntas',
        icon: 'CodepenIcon',
        children: [
            {
                title: 'Lista',
                route: 'questions',
            },
            {
                title: 'Agregar',
                route: 'questions-store',
            }

        ]
    },
    {
        title: 'Estadística',
        icon: 'PieChartIcon',
        route: 'statistics',
        action: 'read',
        resource: 'ACL'
    },
]
